

































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

class Reminder {
  public id: number
  public daysAhead: number
  public timeOfDay: string
  public transport: string
  public type: string
  public status: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = (data.id as number) || null
    this.daysAhead = (data.daysAhead as number) || null
    this.timeOfDay = (data.timeOfDay as string) || ''
    // this.subject = data.subject as string || null
    this.transport = (data.transport as string) || ''
    this.type = (data.type as string) || ''
    this.status = (data.status as string) || ''
  }
}

@Component({})
export default class List extends Vue {
  private reminders = []
  private types = ['ApproachingTime', 'PleaseConfirm']
  private transports = ['Email', 'Sms']

  public mounted(): void {
    this.$axios
      .get(this.url)
      .then((response) => {
        const result = []
        for (let i = 0; i < response.data.data.length; i++) {
          result.push(new Reminder(response.data.data[i]))
        }
        this.reminders = result
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to fetch reminders')
      })
  }

  private get url() {
    return '/v4/site/calendars/' + this.$route.params.calendarId + '/reminders'
  }

  private getRemindersByType(type) {
    const result = []
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i] !== type) {
        continue
      }
      for (let j = 0; j < this.transports.length; j++) {
        result.push(this.findReminderByTypeAndTransport(this.types[i], this.transports[j]))
      }
    }
    return result
  }

  private findReminderByTypeAndTransport(type: string, transport: string) {
    for (let i = 0; i < this.reminders.length; i++) {
      const r = this.reminders[i]
      if (r.type === type && r.transport === transport) {
        return r
      }
    }
    const r = new Reminder()
    r.type = type
    r.transport = transport
    r.status = 'Disabled'
    return r
  }

  private getTransportText(reminder: Reminder) {
    return this.$t('c:booking-reminder:Via ' + reminder.transport)
  }

  private getTimeText(reminder: Reminder) {
    if (!reminder.daysAhead && !reminder.timeOfDay) {
      return this.$t('(Not specified)')
    }
    let text = this.$t('c:booking-reminder:%s days ahead, at %s')
    text = text.replace('%s', reminder.daysAhead ? '' + reminder.daysAhead : '0')
    text = text.replace('%s', reminder.timeOfDay || '(no-time)')
    return text
  }

  private getStatusText(reminder: Reminder) {
    return this.$t(reminder.status)
  }

  private getTypeTitle(type: string) {
    switch (type) {
      case 'ApproachingTime':
        return this.$t('Reminder about approaching time')
      case 'PleaseConfirm':
        return this.$t('Reminder about confirming time')
      default:
        return 'unknown:' + type
    }
  }

  private getEditLink(reminder: Reminder) {
    if (reminder.id) {
      return { name: 'Settings/Booking/Reminders/Edit', params: { id: reminder.id } }
    } else {
      return {
        name: 'Settings/Booking/Reminders/NewForType',
        params: { type: reminder.type, transport: reminder.transport },
      }
    }
  }
}
